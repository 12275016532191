import Api from './Api';
import Csrf from "../apis/Csrf";


export default {

    async login(loginForm) {
        await Csrf.getCookie();
        return Api.post('/login', loginForm);
    },

    async ssoLogin(loginForm) {
        await Csrf.getCookie();
        return Api.post('/sso-login', loginForm);
    },

    async lmsReturnLogin(token) {
        await Csrf.getCookie();
        return Api.post('/lms-return-login', token);
    },

    async preAdmissionLogin(loginForm) {
        await Csrf.getCookie();
        return Api.post('/undergraduate/pre-admission/login', loginForm);
    },

    async logout() {
        await Csrf.getCookie();
        return Api.post('/logout');
    },

    auth() {
        return Api.get('/user');
    },

    async getUserCount() {
        await Csrf.getCookie();
        return Api.get('/users/count');
    },

    async deleteUser(id) {
        await Csrf.getCookie();
        return Api.post('/user/destroy/' + id);
    },


    async register(registrationForm) {
        await Csrf.getCookie();
        return Api.post('/register', registrationForm);
    },

    async getInstitution(id) {
        await Csrf.getCookie();
        return Api.get('/user/institution/' + id);
    },

    async getDepartment(id) {
        await Csrf.getCookie();
        return Api.get('/user/department/' + id);
    },

    async getMyDepartment(dummyRequest) {
        await Csrf.getCookie();
        return Api.get('/user/myDepartment/', dummyRequest);
    },

    async getRoleNames(id) {
        await Csrf.getCookie();
        return Api.get('/user/roleNames/' + id);
    },

    async getMyRoleNames(dummyRequest) {
        await Csrf.getCookie();
        return Api.get('/user/myRoleNames/', dummyRequest);
    },

    async getMyRoles(dummyRequest) {
        await Csrf.getCookie();
        return Api.get('/user/myRoles/', dummyRequest);
    },

    async getMyPermissions(dummyRequest) {
        await Csrf.getCookie();
        return Api.get('/user/myPermissions/', dummyRequest);
    },

    async getMyRolesAndPermissions(dummyRequest) {
        await Csrf.getCookie();
        return Api.get('/user/myRolesAndPermissions/', dummyRequest);
    },

    async uploadProfilePicture(formData) {
        await Csrf.getCookie();
        return Api.post('/profile/picture/upload', formData);
    },

    async resetSingleSingOnPassword(formData) {
        await Csrf.getCookie();
        return Api.post('/user/sso-password/reset', formData);
    },

    async changePassword(formData) {
        await Csrf.getCookie();
        return Api.post('/user/change-password', formData);
    },

    //*****************************************************************************//
    //                          APIs for User ofTYpe(Student)                    //
    //****************************************************************************//


    async registerCandidate(registrationForm) {
        await Csrf.getCookie();
        return Api.post('/register/candidate', registrationForm);
    },

    async registerStudent(registrationForm) {
        await Csrf.getCookie();
        return Api.post('/student/register', registrationForm);
    },

    async deleteStudent(id) {
        await Csrf.getCookie();
        return Api.patch('/student/destroy/' + id);
    },

    async importStudentsFromExcel(registrationForm) {
        await Csrf.getCookie();
        return Api.post('/students/import', registrationForm);
    },

    async importTemporalMatricNumber(registrationForm) {
        await Csrf.getCookie();
        return Api.post('/import-temporal-matric-number', registrationForm);
    },

    async importGraduatingStudentsList(registrationForm) {
        await Csrf.getCookie();
        return Api.post('/import-graduating-students-list', registrationForm);
    },

    async importLateRegistrationExemptionList(registrationForm) {
        await Csrf.getCookie();
        return Api.post('/import-late-registration-exemption-list', registrationForm);
    },

    async exportStudentsToExcel() {
        await Csrf.getCookie();
        return Api.post('/students/export');
    },

    async exportStudentsDataToExcel(exportData) {
        await Csrf.getCookie();
        return Api.post('/students-data/export', exportData, { responseType: 'blob' });
    },

    async updateStudentRecord(id, updateForm) {
        await Csrf.getCookie();
        return Api.patch('/student/update-record/' + id, updateForm);
    },

    async getStudentCount() {
        await Csrf.getCookie();
        return Api.get('/get-students-count');
    },
    async getTotalActiveStudents() {
        await Csrf.getCookie();
        return Api.get('/active-students/count');
    },
    async getTotalReturningStudents() {
        await Csrf.getCookie();
        return Api.get('/returning-students/count');
    },
    async getTotalFreshStudents() {
        await Csrf.getCookie();
        return Api.get('/fresh-students/count');
    },

    async getStudentsList(page, per_page) {
        await Csrf.getCookie();
        return Api.get('/students/'+per_page+'?page='+page);
    },

    async getStudentsListByDepartment(page){
        await Csrf.getCookie();
        return Api.get('/get-students-by-department?page='+page);
    },

    async getStudentByProgrammeAndLevel(session_id, programme_id, level_id, page){
        await Csrf.getCookie();
        return Api.get('/get-student-by-programme-level/' + session_id + '/' + programme_id + '/' + level_id + '?page='+page);
    },

    async getStudentsByProgrammeAndLevel(data, $per_page, page){
        await Csrf.getCookie();
        return Api.post('/get-students-by-programme-level/' + $per_page + '?page='+page, data);
    },

    //---- APIs for profile updates------/
    async updateBioDataProfile(id, updateForm) {
        await Csrf.getCookie();
        return Api.patch('/profile/biodata/' + id, updateForm);
    },

    async updateContactProfile(id, updateForm) {
        await Csrf.getCookie();
        return Api.patch('/profile/contact/' + id, updateForm);
    },

    async updateStudentNextOfKinProfile(id, updateForm) {
        await Csrf.getCookie();
        return Api.patch('/profile/student/update/nextOfKin/' + id, updateForm);
    },

    //*****************************************************************************//
    //                          APIs for User ofTYpe(Lecturer)                    //
    //****************************************************************************//
 /*   async registerLecturer(registrationForm) {
        await Csrf.getCookie();
        return Api.post('/lecturer/register', registrationForm);
    },

    async importLecturersFromExcel(registrationForm) {
        await Csrf.getCookie();
        return Api.post('/lecturers/import', registrationForm);
    },

    async updateLecturerRecord(id, updateForm) {
        await Csrf.getCookie();
        return Api.patch('/lecturer/update/' + id, updateForm);
    },
/*
    async getLecturersList() {
        await Csrf.getCookie();
        return Api.get('/lecturers');
    },

    async getMyLecturers() {
        await Csrf.getCookie();
        return Api.get('hod/myLecturers');
    },

    async getLecturersListByDepartmentID(id) {
        await Csrf.getCookie();
        return Api.get('/lecturers/department/' + id);
    },

    async getLecturerCount() {
        await Csrf.getCookie();
        return Api.get('/get-lecturers-count');
    },
*/

    //*****************************************************************************//
    //                          APIs for Staff                    //
    //****************************************************************************//
    async registerStaff(registrationForm) {
        await Csrf.getCookie();
        return Api.post('/staff/register', registrationForm);
    },


    async updateStaffRecord(id, updateForm) {
        await Csrf.getCookie();
        return Api.patch('/lecturer/update/' + id, updateForm);
    },

    async updateStaffStatus(id, updateForm) {
        await Csrf.getCookie();
        return Api.patch('/lecturer/update-status/' + id, updateForm);
    },

    async getLecturersList(page, per_page) {
        await Csrf.getCookie();
        return Api.get('/lecturers/'+per_page+'?page='+page);
    },

    async getAllLecturers() {
        await Csrf.getCookie();
        return Api.get('/get-all-lecturers');
    },

    async getLecturersByDepartment(page) {
        await Csrf.getCookie();
        return Api.get('/get-by-department/lecturers?page='+page);
    },

    async getLecturersByDepartmentId(department_id) {
        await Csrf.getCookie();
        return Api.get('/get-by-department-id/lecturers/'+department_id);
    },

    async getSupportingStaffList(page, per_page) {
        await Csrf.getCookie();
        return Api.get('/supporting-staff/'+per_page+'?page='+page);
    },

    async getAllStaffList() {
        await Csrf.getCookie();
        return Api.get('/staff');
    },

    async getAllStaffLists() {
        await Csrf.getCookie();
        return Api.get('/staffs');
    },

    async getMyLecturers() {
        await Csrf.getCookie();
        return Api.get('hod/myLecturers');
    },

    async getMyAdmins() {
        await Csrf.getCookie();
        return Api.get('/admins');
    },

    async getLecturersListByDepartmentID(id) {
        await Csrf.getCookie();
        return Api.get('/lecturers/department/' + id);
    },

    async getLecturersListByHost(data) {
        await Csrf.getCookie();
        return Api.post('/lecturers/host', data);
    },

    async exportList(url) {
        // await Csrf.getCookie();
        return Api.get(url, { responseType: 'blob' });
    },

    async getPaginatedLecturersListByHost(data) {
        await Csrf.getCookie();
        return Api.post('/paginated-lecturers/host', data);
    },

    async getLecturersListByFacultyID(id) {
        await Csrf.getCookie();
        return Api.get('/lecturers/faculty/' + id);
    },

    async getLecturerCount() {
        await Csrf.getCookie();
        return Api.get('/get-lecturers-count');
    },

    async deleteStaff(id) {
        await Csrf.getCookie();
        return Api.post('/staff/destroy/' + id);
    },

    async importLecturersFromExcel(registrationForm) {
        await Csrf.getCookie();
        return Api.post('/lecturers/import', registrationForm);
    },

    async getDirectorDashboardCountsByHostID(host_id, host_type) {
        await Csrf.getCookie();
        return Api.get('/director/dashboard/counts/' + host_id + '/' + host_type);
    },

//*****************************************************************************//
    //                     Pre-admission screening     APIs                   //
    //****************************************************************************//

    async verifyCandidateData(verificationForm) {
        await Csrf.getCookie();
        return Api.post('/candidate/data/verify', verificationForm);
    },

    async activateCandidateAccount(activationForm) {
        await Csrf.getCookie();
        return Api.post('/candidate/account/activate', activationForm);
    },

    async importCandidatesFromExcel(formData) {
        await Csrf.getCookie();
        return Api.post('/candidates/import', formData);
    },

    async importCandidatesProgrammeFromExcel(formData) {
        await Csrf.getCookie();
        return Api.post('/candidates-programme/import', formData);
    },

    async importPostUTMEResultsFromExcel(formData) {
        await Csrf.getCookie();
        return Api.post('/post-utme/results/import', formData);
    },

    async downloadPOSTUTMEResultTemplate() {
        await Csrf.getCookie();
        return Api.get('post-utme-results/import/template', { responseType: 'blob' });
    },

    async getCandidatesList() {
        await Csrf.getCookie();
        return Api.get('/candidates');
    },

    async getCandidateByID(id) {
        await Csrf.getCookie();
        return Api.get('/candidate/' + id);
    },

    async getCandidateBufferByID(id) {
        await Csrf.getCookie();
        return Api.get('/candidate/buffer/' + id);
    },

    async getCandidateInfo(id) {
        await Csrf.getCookie();
        return Api.get('/candidate/info/' + id);
    },

    async getAuthCandidateDetails() {
        await Csrf.getCookie();
        return Api.get('candidate/auth-candidate/details');
    },

    async getAnnouncements(session_id, recipient_type, institute_id = null,  page) {
        await Csrf.getCookie();
        return Api.get('/my-announcements/' + session_id + '/' + recipient_type + '/' + institute_id + '?page='+page);
    },

    async importCandidatesAdmissionListFromExcel(formData) {
        await Csrf.getCookie();
        return Api.post('/upload-candidates-admissions-list', formData);
    },
};
