<template>
  <v-app id="inspire">
    <v-main>
            <Navigation
              :authenticatedUser="auth_user"

            >  <!---:roles="role_names" -->
                <template v-slot:items>
                  <SiderBarItems
                    :items="side_nav_items"
                    :roles="role_names"
                    :permissions="permission_names"
                    usertype="department"
                  />
                </template>
            </Navigation>
        <template>
            <div class="container mx-6 my-4 mx-auto">
                 <Overview  v-if="$route.fullPath === '/department'" v-bind:authenticatedUser="auth_user"></Overview>
            </div>
        <router-view  :key="$route.fullPath"/>
        </template>
      </v-main>
  </v-app>
</template>

<script>
import Navigation from '../../Navigation_modules/CustomNavigationDrawer'
import SiderBarItems from '../../Navigation_modules/SideBarItems'
import Overview from './DepartmentOverview.vue'
import User from "../../../apis/User";

export default {
    name:"DepartmentDashboard",
    components: { Navigation, Overview, SiderBarItems },

    data() {
        return {
          auth_user:null,
          permission_names:[],
          role_names:[],
          side_nav_items: [],

          side_nav_items: [
            { text: 'Dashboard', icon: 'dashboard', route: 'overview', permissions:[] },
            { text: 'Students', icon: 'students', route: 'students', permissions:[] },
            { text: 'Student Registration List', icon: 'courses', route: 'students-registration-list', permissions:[] },
            { text: 'Assessment Score', icon: 'assessment_score', route: 'assessment-score', permissions:[] },
            { text: 'Students Results', icon: 'students', route: 'students-results', permissions:[] },
            { text: 'Cummulative Result', icon: 'students', route: 'cummulative-results', permissions:[] },
            { text: 'Lecturers', icon: 'lecturer', route: 'staff', permissions:[] },
            { text: 'Programmes', icon: 'programmes', route: 'programmes', permissions:[] },
            { text: 'Transfer Requests', icon: 'transfer-request', route: 'transfer-requests/inbound', permissions:[] },
            { text: 'Courses', icon: 'courses', route: 'courses', permissions:[] },
            { text: 'Course Allocations', icon: 'course-allocation', route: 'course-allocations', permissions: [] },
            { text: 'Result Publication', icon: 'courses', route: 'course-publications', permissions: [] },
            { text: 'Course Registrations', icon: 'students', route: 'course-registrations',permissions: []},
            { text: 'Extra Credit Applications', icon: 'course-reg', route: 'extra-credit-applications', permissions:[]},
            { text: 'Academic Advisers', icon: 'academic-advisers', route: 'academic-advisers', permissions:[] },
           { text: 'Examination Officers', icon: 'examination-office', route: 'appoint-exam-officer', permissions:[] },
            { text: 'Progression', icon: 'progression', route: 'departmental-progression', permissions:[] },
            { text: 'Curriculum Management', icon: 'curriculum', route: 'curriculum/departmental-curriculum-dashboard', permissions: [] },

          ]
        }
    },
     mounted() {
      User.auth().then(response=>{
        this.auth_user = response.data.data;
      });
    }
}
</script>

<style>

</style>