/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
*/

require('./bootstrap');
import SvgVue from 'svg-vue';

import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css';

import router from './router';
import Vue from 'vue';

import InstitutionAPI from "./apis/Institution";

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.use(BootstrapVue);

//  import VueCryptojs from 'vue-cryptojs'

//  Vue.use(VueCryptojs)
/**
 * adding pinia for global storage of users' roles & permissions
 */
import { createPinia, PiniaVuePlugin } from 'pinia';
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
//---persistt store accross page reloads
pinia.use((context) => {
    const storeId = context.store.$id;
    const serializer = {
        serialize: JSON.stringify,
        deserialize: JSON.parse
    };


    //--load storage from sessionStorage
    const persisted_storage = serializer.deserialize(window.localStorage.getItem(storeId))
    if (persisted_storage) {
        // const decryptedState = $CryptoJS.AES.decrypt(persisted_storage, cryptoAESKey).toString($CryptoJS.enc.Utf8)
        // console.log(decryptedState)
        context.store.$patch(persisted_storage);
    }

    //--listen to changes and update sessionStorage
    context.store.$subscribe((mutation, state) => {
        // const encryptedState = $CryptoJS.AES.encrypt(serializer.serialize(state), cryptoAESKey).toString()
        // console.log(encryptedState)
        window.localStorage.setItem(storeId, serializer.serialize(state))
    });
});



Vue.use(SvgVue);

window.Vue = require('vue').default;
// Vue.use(VueRouter);


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */




// Vue.component('request-email', require('./components/Views/PasswordReset/RequestEmail.vue').default);

//  Vue.component('VueRecaptcha', require('vue-recaptcha').default);

Vue.component('reset-password', require('./components/Views/PasswordReset/ResetPassword.vue').default);
Vue.component('reset-success', require('./components/Views/PasswordReset/ResetSuccess.vue').default);
Vue.component('reset-failure', require('./components/Views/PasswordReset/ResetFailure.vue').default);

Vue.component('enrollment-form', require('./components/Views/Enrollment/EnrollmentMultistepForm.vue').default);
Vue.component('t-breadcrumbs', require('./components/Breadcrumb.vue').default);
Vue.component('c-breadcrumbs', require('./components/CustomBreadcrumb.vue').default);
Vue.component('Toast', require('./components/Toast.vue').default);
// import Toast from "./components/Toast.vue";
// Vue.component('Toast', Toast);


Vue.filter('trailing_zero', val => {
    if (!val || isNaN(parseInt(val))) return '';
    if (parseInt(val) < 10) return '0' + val;
});

import { useUserStore } from "./stores/userStore";
import { useLevelAdvisingStore } from "./stores/levelAdvisingStore";
import { useInstitutionStore } from "./stores/institutionStore";
import { mapStores } from 'pinia';
import { suitableTextColor } from "./util/colorUtils";

Vue.mixin(
    {
        data() {
            return {

                theme_is_loaded: false,
                overlay: false,
                toast_data: {
                    text: '',
                    color: '',
                    time: null
                },

                create_update_loading: false,
                delete_loading: false,
                cancel_button_disabled: false,
            }
        },
        computed: {

            ...mapStores(useUserStore),
            ...mapStores(useLevelAdvisingStore),
            ...mapStores(useInstitutionStore),

            primary_text_color() { //generates dynamic text color suitable for the choses 'primary color' in the themes
                return suitableTextColor(sessionStorage.getItem('primaryColor'));
            },

            secondary_text_color() { //generates dynamic text color suitable for the choses 'secondary color' in the themes
                return suitableTextColor(sessionStorage.getItem('secondaryColor'));
            },

            bucketUrl() {
                return process.env.MIX_BUCKET_URL;
            },

            reCaptchaSiteKey() {
                return process.env.MIX_GOOGLE_RECAPTCHA_SITE_KEY;
            },

            cryptoAESKey() {
                return process.env.MIX_CRYPTO_AES_KEY;
            },

            breadcrumb_items() {
                const routePath = this.$router.currentRoute.path;

                // Split the path into segments and filter out empty ones
                let pathSegments = routePath.split('/').filter(segment => segment !== '');

                // Calculate the parent path based on the number of segments
                const difference = pathSegments.length - 1;
                const parent_path = Array(difference).fill('..').join('/');

                // Handle the segment declaration for the breadcrumbs
                const first_segment = '/' + pathSegments[0];

                let dashboard_text = pathSegments[0].includes('-') || pathSegments[0].includes('_')
                    ? pathSegments[0].replace(/[-_]/g, ' ')
                    : pathSegments[0];

                // Initialize the breadcrumbs array with the first breadcrumb
                let breadcrumbs = [
                    {
                        text: dashboard_text.toUpperCase() + ' DASHBOARD',
                        disabled: false,
                        href: parent_path + first_segment,
                    },
                ];
                const admin_dashboard_breadcrumb = {
                    text: 'ADMIN DASHBOARD',
                    disabled: false,
                    href: parent_path + '/../admin'
                };

                // Declare components of admin with separate dashboard
                let admin_sub_dashboards =['finance', 'hostel', 'admissions', 'grading-system', 'users', 'curriculum', 'elections'];
                
                // Declare components of 'institute' with separate dashboard
                let institute_sub_dashboards = ['admissions', 'finance','users','grading-system','curriculum'];
                
                // Check if the first segment should be prepended with admin dashboard breadcrumb
                if (admin_sub_dashboards.includes(pathSegments[0])) {
                    
                    breadcrumbs.unshift(admin_dashboard_breadcrumb);
                }
                
                // Check if the second segment is a component of 'institute' with sub dashboard
                        else if ('institute'.includes(pathSegments[0])) {
        
                            const institutes_dashboard_breadcrumb = {
                                    text: 'INSTITUTES DASHBOARD',
                                    disabled: false,
                                    href: parent_path + '/../admin/institutes'
                                }
        
                            breadcrumbs.unshift(admin_dashboard_breadcrumb,institutes_dashboard_breadcrumb);
                            }

                let temp_breadcrumbs = []; // Temporary array to hold the additional breadcrumbs
                let breadcrumb_segment = '';
                
                // Loop through the path segments to create breadcrumbs
                pathSegments.forEach((segment, index) => {
                    if (index === 0 || /\d/.test(segment)) {
                        return; // Skip adding this breadcrumb
                        }

                    if (index === 1 && institute_sub_dashboards.includes(segment)) {
                        
                        return; // Skip adding this breadcrumb too 
                    }

                    breadcrumb_segment += '/' + segment;
                    let breadcrumb_text = segment.includes('-') || segment.includes('_')
                        ? segment.replace(/[-_]/g, ' ')
                        : segment;

                    let breadcrumb = {
                        text: breadcrumb_text.toUpperCase().trim(),
                        disabled: false,
                        href: first_segment + breadcrumb_segment
                    };
                    
                    temp_breadcrumbs.push(breadcrumb);
                });
                
                // Combine the initial breadcrumbs with the additional ones
                breadcrumbs = [...breadcrumbs, ...temp_breadcrumbs];

                //check if the if component is a sub-dasboard and removes multiple '-dashboard'
                // let last_segment = pathSegments[pathSegments.length - 1].replace(/-/g, ' ');
                // let second_breadcrumb = breadcrumbs[1]['text'].toLowerCase()

                // if (last_segment === second_breadcrumb) {
                //     breadcrumbs.splice(1,1)
                // }
                    // Check if there are any breadcrumbs to disable
                    if (breadcrumbs.length > 0) {
                        breadcrumbs[breadcrumbs.length - 1].disabled = true; // Disable the last breadcrumb object
                    }
                return breadcrumbs;
                
            }

        },

        mounted: function () {
            if (sessionStorage.getItem('primaryColor') != undefined || sessionStorage.getItem('primaryColor') != null) {
                let theme = {
                    'primary': sessionStorage.getItem('primaryColor'),
                    'secondary': sessionStorage.getItem('secondaryColor'),
                    'accent': sessionStorage.getItem('accentColor'),
                    'tertiary': sessionStorage.getItem('tertiaryColor'),
                }
                this.loadThemeTextColorsStyling()
                this.loadThemeColorsStyling(theme);
                this.loadThemes(theme);
            } else {
                this.verifySubdomain();
            }
        },

        methods: {
            loadThemes(institution_theme) {
                if (this.$vuetify.theme.themes) {
                    this.$vuetify.theme.themes.light.primary = institution_theme.primary;
                    sessionStorage.setItem('primaryColor', institution_theme.primary);
                    this.$vuetify.theme.themes.light.secondary = institution_theme.secondary;
                    sessionStorage.setItem('secondaryColor', institution_theme.secondary);
                    this.$vuetify.theme.themes.light.accent = institution_theme.accent;
                    sessionStorage.setItem('accentColor', institution_theme.accent);
                    this.$vuetify.theme.themes.light.tertiary = institution_theme.tertiary;
                    sessionStorage.setItem('tertiaryColor', institution_theme.tertiary);
                    this.$vuetify.theme.themes.light.textColor = institution_theme.bgTextColor;
                }
            },

            verifySubdomain: function () {
                if (this.theme_is_loaded) {
                    return;
                }
                InstitutionAPI.verifySubdomain()
                    .then(response => {
                        this.loadThemes(response.data.institution.theme);
                    })
                    .catch(error => {
                    });
            },

            loadThemeColorsStyling(theme) {
                document.documentElement.style.setProperty('--primary', theme['primary']);
                document.documentElement.style.setProperty('--secondary', theme['secondary']);
                document.documentElement.style.setProperty('--tertiary', theme['tertiary']);
                document.documentElement.style.setProperty('--accent', theme['accent']);
            },

            loadThemeTextColorsStyling() {
                //updates the primary and secondary text color in the root styling
                document.documentElement.style.setProperty('--primaryText', this.primary_text_color);
                sessionStorage.setItem('primaryTextColor', this.primary_text_color); //adds the color to session storage
                document.documentElement.style.setProperty('--secondaryText', this.secondary_text_color);
                sessionStorage.setItem('secondaryTextColor', this.secondary_text_color); //adds the color to session storage
               
            },

        handleErrorToast(error) {
            let errors = null;
        
            if (typeof error === "string") {
                this.toast('warning', error);
            } else {
                const status = error.response?.status;
                const error_text = error.response?.statusText;
                const error_data = error.response?.data;
        
                if (error_data?.errors) {
                    const errorMessages = error_data.errors;
                    const firstKey = Object.keys(errorMessages)[0];
                    if (firstKey) {
                        errors = errorMessages[firstKey][0];
                    }
                } else if (error_data?.message) {
                    errors = error_data.message;
                }
        
                (status && status >= 400 && status < 500)? this.toast('warning', errors) : this.toast('error', error_text);
                
            }
            this.stopLoading();
        },
        
            handleSuccessToast(response, flag = null) {
                typeof response === "string" ? this.toast('success', response) : this.toast('success', response.data.message); this.stopLoading();
            
                if (typeof this.close === 'function') {
                    flag ? this.close(flag) : this.close()
                }
            
                const methodNames = Object.getOwnPropertyNames(this.$options.methods);

                // Filter the methods to find those containing 'closeDelete'
                const closeDeleteMethods = methodNames.filter(methodName =>
                    methodName.includes('closeDelete')
                );
                closeDeleteMethods.length > 0 ? closeDeleteMethods.forEach(method => this[method]()) : null; //if any method on vue instance contains substring 'closeDelete', the method should be called. This is to avoid repetitive method calls 

        },

            //------------------ snackbar trigger-----------------------------------
            toast(color, text) {
                this.toast_data.color = color;
                this.toast_data.text = text;
                this.toast_data.time = new Date().getTime();

            },
            stopLoading() {
                if (typeof this.create_update_loading !== 'undefined') {
                    this.create_update_loading = false;
                }
                if (typeof this.delete_loading !== 'undefined') {
                    this.delete_loading = false;
                }
                if (typeof this.cancel_button_disabled !== 'undefined') {
                    this.cancel_button_disabled = false;
                }
            },

            async downloadProtectedData(api_url, download_file_name, file_type) {
                const token = localStorage.getItem('tertiary_token');
                try {
                    //this.overlay = true;
                    const response = await axios.get(
                        api_url,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                            responseType: "blob"
                        });

                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    switch (file_type) {
                        case 'pdf':
                        case 'PDF':
                        case 'Pdf':
                            link.setAttribute("download", download_file_name + ".pdf");
                            break;
                        case 'Excel':
                        case 'excel':
                        case 'EXCEL':
                            link.setAttribute("download", download_file_name + ".xlsx");
                            break;
                        default:
                            link.setAttribute("download", download_file_name + ".pdf");
                            break;
                    }
                    //link.setAttribute("download", this.download_file_name + ".pdf"); // Specify the name of the file to be downloaded
                    document.body.appendChild(link);
                    link.click();
                    //this.overlay = false;
                } catch (error) {
                    // Handle errors
                    alert('Error: downloading failed');
                }
            },
        },
    })


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    vuetify,
    router,
    pinia
});
